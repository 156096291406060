import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { BoxConfigurationService } from './box-configuration.service';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CustomBrandConfigurationService {
	isDefinedCustomBrand: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(private boxConfigurationService: BoxConfigurationService) {
	}

	public checkApplicationBranding(): boolean {
		return this.isDefinedCustomBrand.getValue();
	}

	public setDefinedCustomBrand(val: boolean): void {
		this.isDefinedCustomBrand.next(val);
	}

	public checkApplicationBrand(): void {
		if (this.boxConfigurationService.isBoxFeatureEnabled()) {
			const brandInfo: string = environment.brand.brandCode;
			this.boxConfigurationService.getBoxParams(brandInfo).pipe(
				catchError((err) => {
					this.setDefinedCustomBrand(false);
					return of(null);
				}),
			).subscribe((data) => {
				if (data) {
					this.boxConfigurationService.boxParam.next(data);
					this.setDefinedCustomBrand(true);
				}
			});
		}
	}
}
